import React from 'react';
import { MapContainer, Marker, Popup, TileLayer, useMapEvents  } from 'react-leaflet';
import VermessungPointMarker from "./VermessungPointMarker";
import L from 'leaflet';

const MapComponent = ({children, setMapCenterPosition, ref}) => {
    const position = [52.616163464359566, 9.077535492746305]; // Beispielposition - Latitude und Longitude
    //const position = [50.56471054159478, 10.120639165140421]; // Beispielposition - Latitude und Longitude


    const handleMapMove = (event) => {
        const { lat, lng } = event.target.getCenter();
        const zoomLevel = event.target.getZoom();
        console.log(`Neue Position: ${lat}, ${lng}, Zoomstufe: ${zoomLevel}`);
        setMapCenterPosition({lat, lng});

    };
    function EventListenerComponent() {
        useMapEvents({
            moveend: handleMapMove, // Dieses Event wird ausgelöst, wenn die Karte verschoben wurde und die Bewegung abgeschlossen ist
        });
        return null;
    }

    const mapSettings = {
        center: position,
        zoom: 21,
        maxNativeZoom: 21,
        maxZoom: 30,
    //    dragging: false, // Bewegen (Panning) deaktivieren
      //  doubleClickZoom: false, // Doppelklick zum Zoomen deaktivieren
     //   scrollWheelZoom: false, // Zoomen mit dem Mausrad deaktivieren
    //    touchZoom: false, // Zoomen bei Berührung deaktivieren
   //     zoomControl: false, // Zoom-Steuerung deaktivieren
        /**
         *  <TileLayer
         *                 url="https://services.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png"
         *                 attribution="Tiles &copy; Esri"
         *             />
         */
    };
    // Erstellen Sie eine benutzerdefinierte Icon-Instanz mit der SVG-Grafik

    return (
        <MapContainer {...mapSettings} ref={ref}




                      style={{ height: '800px', width: '100%' }}>
            <EventListenerComponent />

            <TileLayer
                url="https://api.cva.de/ap25_f/{z}/{y}/{x}?secret=ksP4Qb8Zu9F58Qdgf85tbMJ2X2d3g2D7LZEzGPt475rPgFAc6E3by43urJEMRyE2"
                attribution="Tiles &copy; Esri"
                maxNativeZoom={21}
                minZoom={0}
                maxZoom={30}
            />

            {children}
        </MapContainer>
    );
};

export default MapComponent;
